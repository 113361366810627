@import "_variables";

.view-price-quantity {
  font-size: 19pt;
  line-height: 15pt;
  color: $color-marine;
  margin-top: 23px;
}

.view-price-quantity > span {
  font-size: 28pt;
  letter-spacing: -0.64pt;
}

.view-price-quantity > sup {
  font-size: 100pt;
  color: green;
}

.remove-basket {
  color: $color-carnation;
  margin-top: 15px;

  &:hover {
    font-weight: bold;
    color: $color-carnation;
    margin-top: 15px;
  }
}

.total-amount {
  font-size: 35pt;
  line-height: 33pt;
  color: $color-marine;
}

.submit-btn {
  margin: 10px;
  height: 90px;
  border-radius: 50px;
  border: 0;
  font-size: 18px;
  line-height: 24px;
  background-color: $color-yellowish-green;
  color: $color-white;
  max-width: 100%;
  &:disabled{
    background-color: $cloudy-blue-hover;
  }
}

.basket-title-text {
  margin-left: 50px;
  color: $color-title-blue;
}

.items-count-text {
  color: #aab6d6;
}

// .basket-card {
//   margin-left: 35px;
// }

.basket-summary-text {
  margin-left: 50px;
  margin-bottom: 25px;
}

// .summary-card {
//   margin-right: 50px;
// }

.basket-card-text {
  color: #122a60;
}

// @media (max-width: 768px) {
//   .basket-card {
//     margin-left: 0px;
//   }
//   .summary-card {
//     margin-right: 0px;
//   }
// }
