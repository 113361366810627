@mixin button-common{ 
  height: fit-content;
  justify-content: center;
  text-align: center;
  display: flex;
  padding: 10px;
  align-items: center;
  overflow: hidden;
}

.sign-in-button {
  font-weight: 500;
  background: transparent;
  color: $white;
  border: hidden;
  box-shadow: none;
  padding: 0;
  position: relative;

  &:hover {
    color: $white-hover;
  }
}

.confirm-button {
  background-color: $yellowish-green;
  color: $white;
  border-radius: 26px;
  border: none;
  width: fit-content;
  height: 43px;
  margin-right: 20px;
  font-family: "Poppins", sans-serif;
  @include button-common;
  &:hover {
    color: $white-hover;
    background-color: $yellowish-green-hover;
  }
}

.reprocess-button {
  background-color: $dark-slate-blue;
  color: $white;
  border-radius: 26px;
  border: none;
  width: fit-content;
  height: 43px;
  margin-right: 20px;
  font-family: "Poppins", sans-serif;
  @include button-common;
  &:hover {
    color: $white-hover;
    background-color: $dark-slate-blue-hover;
  }
}

.order-detail-remove-button {
  background-color: transparent;
  border-radius: 26px;
  border: none;
  width: 40px;
  height: 40px;
  font-family: "Poppins", sans-serif;
  &:hover {
    background-color: $color-pale-grey;
  }
}

.order-detail-retry-button {
  background-color: transparent;
  border-radius: 26px;
  border: none;
  width: 40px;
  height: 40px;
  font-family: "Poppins", sans-serif;
  @include button-common;
  &:hover {
    background-color: $color-pale-grey;
  }
}

.order-detail-view-detail-button {
  background-color: transparent;
  border-radius: 26px;
  border: none;
  width: 40px;
  min-width: 20px !important;
  height: 40px;
  font-family: "Poppins", sans-serif;
  &:hover {
    background-color: $color-pale-grey;
  }
}

.order-retry-all-button {
  background-color: $color-pale-grey;
  border-radius: 26px;
  border: none;
  width: 140px;
  height: 40px;
  font-family: "Poppins", sans-serif;
  &:hover {
    background-color: $color-pale-grey-hover;
  }
}

.resubmit-button {
  background-color: $dark-slate-blue;
  color: $white;
  width: 190px;
  height: 55px;
  border-radius: 40px;
  border: none;
  &:hover {
    color: $white-hover;
    background-color: $dark-slate-blue-hover;
  }
  &:disabled {
    opacity: 0.5;
  }
}

.withdraw-button {
  background-color: $mango;
  color: $white;
  border-radius: 26px;
  border: none;
  width: fit-content;
  height: 43px;
  font-family: "Poppins", sans-serif;
  &:hover {
    color: $white-hover;
    background-color: $mango-hover;
  }
}

.refund-button {
  background-color: $carnation;
  color: $white;
  border-radius: 26px;
  border: none;
  width: fit-content;
  height: 43px;
  font-family: "Poppins", sans-serif;
  &:hover {
    color: $white-hover;
    background-color: $carnation-hover;
  }
}

.cancel-button {
  background-color: $cloudy-blue;
  color: $white;
  border-radius: 26px;
  border: none;
  width: fit-content;
  height: 43px;
  font-family: "Poppins", sans-serif;
  margin-right: 20px;
  &:hover {
    color: $white-hover;
    background-color: $cloudy-blue-hover;
  }
}

.send-package-button {
  background-color: $color-pale-grey;
  color: $sapphire;
  border-radius: 26px;
  border: none;
  width: fit-content;
  height: 43px;
  margin-right: 20px;
  font-family: "Poppins", sans-serif;
  @include button-common;
  &:hover {
    background-color: $color-pale-grey-hover;
  }
}

.resend-button {
  margin-top: 15px;
  background-color: $color-pale-grey;
  color: $reject-button;
  border-radius: 17px;
  border: none;
  width: fit-content;
  height: 33px;
  font-family: "Poppins", sans-serif;
  font-size: 13px;

  &:hover {
    background-color: $color-pale-grey-hover;
  }
}

.send-button {
  background-color: $color-pale-grey;
  color: $sapphire;
  border-radius: 17px;
  border: none;
  width: 73px;
  height: 34px;
  font-family: "Poppins", sans-serif;
  font-size: 13px;
  &:hover {
    background-color: $color-pale-grey-hover;
  }
}

.create-package-button {
  background-color: $color-pale-grey;
  color: $sapphire;
  border-radius: 26px;
  border: none;
  width: fit-content;
  height: 43px;
  font-family: "Poppins", sans-serif;
  margin-right: 20px;
  @include button-common;
  &:hover {
    background-color: $color-pale-grey-hover;
  }
}

.create-button {
  background-color: $yellowish-green;
  color: $white;
  border-radius: 40px;
  border: none;
  width: 332px;
  height: 65px;
  font-family: "Poppins", sans-serif;
  font-size: 15px;
  
  &:disabled {
    opacity: 0.5;
  }
  &:hover {
    color: $white-hover;
    background-color: $yellowish-green-hover;
  }
}

.detail-button {
  background-color: $color-pale-grey;
  color: $sapphire;
  border-radius: 26px;
  border: none;
  min-width: 120px;
  width: fit-content;
  max-width: 150px;
  height: 43px;
  font-family: "Poppins", sans-serif;
  img {
    background-repeat: no-repeat;
    background-position: center;
    background-image: url("../images/icons/edit-icon.svg");
    background-size: 20px;
  }

  &:disabled{
    opacity: .6;
    &:hover{
      background-color: $color-pale-grey;
      color: $sapphire;
    }
  }

  &:hover {
    color: $white-hover;
    background-color: $sapphire;

    img {
      background-repeat: no-repeat;
      background-position: center;
      color:white;
      background-image: url("../images/icons/edit-icon-white.svg");

    }
  }

  


}
.account-module-button {
  background-color: $color-pale-grey;
  color: $sapphire;
  border-radius: 26px;
  border: none;
  min-width: 120px;
  width: fit-content;
  max-width: 150px;
  height: 43px;
  font-family: "Poppins", sans-serif;
  img {
    background-repeat: no-repeat;
    background-position: center;
    background-image: url("../images/icons/account-module-icon.svg");
    background-size: 20px;
  }

  &:disabled{
    opacity: .6;
    &:hover{
      background-color: $color-pale-grey;
      color: $sapphire;
    }
  }

  &:hover {
    color: $white-hover;
    background-color: $sapphire;

    img {
      background-repeat: no-repeat;
      background-position: center;
      background-image: url("../images/icons/account-module-icon-white.svg");

    }
  }
}


.order-detail-button {
  background-color: $cloudy-blue;
  color: $white;
  border-radius: 26px;
  margin-right: 20px;
  border: none;
  min-width: 120px;
  width: fit-content;
  height: 43px;
  font-family: "Poppins", sans-serif;
  @include button-common;
  &:hover {
    color: $white-hover;
    background-color: $cloudy-blue-hover;
  }
}

.reject-button {
  background-color: $reject-button;
  color: $white;
  border-radius: 26px;
  border: none;
  width: fit-content;
  height: 43px;
  font-family: "Poppins", sans-serif;
  margin-right: 20px;
  @include button-common;
  &:hover {
    color: $white-hover;
    background-color: $reject-button-hover;
  }
}

.go-back-button {
  text-align: left;
  background-color: transparent;
  color: $cloudy-blue;
  border-radius: 26px;
  border: none;
  font-family: "Poppins", sans-serif;
  &:hover {
    color: $cloudy-blue-hover;
  }
}

.view-detail-link {
  transform: rotate(180deg);
  text-align: left;
  background-color: transparent;
  border-radius: 26px;
  border: none;
  font-family: "Poppins", sans-serif;
  margin-left: 5px;
  &:hover {
    background-color: $white-hover;
  }
}

.invoice-order-detail-link {
  transform: rotate(180deg);
  text-align: left;
  background-color: $dark-slate-blue;
  border-radius: 26px;
  border: none;
  font-family: "Poppins", sans-serif;
  margin-left: 5px;
  &:hover {
    background-color: $dark-slate-blue-hover;
  }
}

.modal-resubmit-button {
  background-color: $yellowish-green;
  border-radius: 40px;
  border: none;
  font-family: "Poppins", sans-serif;
  color: $white;
  width: 160px;
  height: 64px;
  margin-top: 36px;
  &:hover {
    color: $white-hover;
    background-color: $yellowish-green-hover;
  }
  &:disabled {
    opacity: 0.5;
  }
}

.modal-reject-button {
  background-color: $reject-button;
  color: $white;
  border-radius: 40px;
  border: none;
  font-family: "Poppins", sans-serif;
  margin-left: 27px;
  margin-top: 36px;
  width: 160px;
  height: 64px;
  &:hover {
    color: $white-hover;
    background-color: $reject-button-hover;
  }
}

.button {
  &.large {
    border-radius: 2rem;
    border: 0;
    font-size: 0.95rem;
    line-height: 1.4rem;
    max-width: 100%;
    padding: 0.75rem 3rem;
    width: auto;
    outline: none;
    background-color: $color-yellowish-green;
    color: $color-white;
  }

  &.text {
    background: none;
    border: none;
    font-size: 0.85em;
    font-weight: 400;
  }

  &.back {
    color: $cloudy-blue;
    position: relative;

    &::before {
      width: 1rem;
      height: 1rem;
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='5.833' height='10.405' viewBox='0 0 5.833 10.405'%3E%3Cpath id='Path_6864' data-name='Path 6864' d='M963.694,536.727l4.124-4.124a.631.631,0,0,0-.892-.893l-4.572,4.571a.631.631,0,0,0,0,.893l4.571,4.57a.628.628,0,0,0,.446.187h0a.632.632,0,0,0,.446-1.078Z' transform='translate(-962.169 -531.526)' fill='%23aab6d7'/%3E%3C/svg%3E%0A");
      background-repeat: no-repeat;
      content: " ";
      display: block;
      background-position: center;
      position: absolute;
      left: -1rem;
      top: 0.23rem;
    }
  }

  &.icon {
    &.add {
      width: 1rem;
      height: 1rem;
      background: none;
      border: none;
      position: relative;
      border-radius: 0.4rem;
      padding: 1rem;
      background-color: $color-pale-grey !important;

      &::before {
        width: 100%;
        height: 100%;
        position: absolute;
        content: " ";
        background-repeat: no-repeat;
        background-position: center;
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='15.8' height='15.8' viewBox='0 0 15.8 15.8'%3E%3Cpath id='Path_6865' data-name='Path 6865' d='M957.766,531.034h-6.1v-6.1a.9.9,0,1,0-1.8,0v6.1h-6.1a.9.9,0,0,0,0,1.8h6.1v6.1a.9.9,0,0,0,1.8,0v-6.1h6.1a.9.9,0,1,0,0-1.8Z' transform='translate(-942.866 -524.034)' fill='%23264ea7'/%3E%3C/svg%3E%0A");
      }
    }
  }

  &.filter-actions {
    font-weight: 400 !important;
    width: 100%;
    height: 2.5rem;
    font-size: 0.85rem;
    border-radius: 0.5rem;

    &.done {
      background-color: $color-accent !important;
      border: none;
      color: $white;
      margin-left: 0.2rem;
    }

    &.clear-all {
      background: none !important;
      border: 1px solid $color-pale-grey;
      margin-right: 0.2rem;
    }
  }
}

.promotion-header-change-button {
  background-color: transparent;
  color: $dark-slate-blue;
  border-radius: 26px;
  border: none;
  font-family: "Poppins", sans-serif;
  margin-left: 10px;
}

.order-confirm-yes-button {
  background-color: $yellowish-green;
  color: $white;
  border-radius: 40px;
  border: none;
  font-family: "Poppins", sans-serif;
  width: 340px;
  height: 60px;
  margin-top: 30px;
  &:hover {
    color: $white-hover;
    background-color: $yellowish-green-hover;
  }
}

.order-confirm-no-button {
  background-color: $cloudy-blue;
  color: $white;
  border-radius: 40px;
  border: none;
  font-family: "Poppins", sans-serif;
  width: 340px;
  height: 60px;
  margin-top: 10px;
  margin-bottom: 60px;
  &:hover {
    color: $white-hover;
    background-color: $cloudy-blue-hover;
  }
}

.order-confirm-retry-button {
  background-color: $color-pale-grey;
  color: $color-sapphire;
  border-radius: 40px;
  border: none;
  font-family: "Poppins", sans-serif;
  width: 340px;
  height: 60px;
  margin-top: 30px;
  &:hover {
    background-color: $color-pale-grey-hover;
  }
}

.order-confirm-remove-button {
  background-color: $reject-button;
  color: $white;
  border-radius: 40px;
  border: none;
  font-family: "Poppins", sans-serif;
  width: 340px;
  height: 60px;
  margin-top: 30px;
  &:hover {
    color: $white-hover;
    background-color: $reject-button-hover;
  }
}


.my-account-button {
  color: #000;
  border-radius: 13px;
  border: 1px solid $purple;
  width: fit-content;
  height: 45px;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  background-color: $white;
  &:hover {
    background-color: none;
    border-color: black;
  }
}

.logout-button {
  color: #000;
  border-radius: 13px;
  border: 1px solid $purple;
  width: fit-content;
  height: 45px;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  background-color: $white;
  &:hover {
    background-color: none;
    border-color: black;

  }
}

.new-promotion-finish-button {
  background-color: $yellowish-green;
  color: $white;
  border-radius: 10px;
  border: none;
  width: 100%;
  height: 80px;
  font-family: "Poppins", sans-serif;
  border-radius: 60px;
  margin-top: 20px;
  &:hover {
    color: $white-hover;
    background-color: $yellowish-green-hover;
  }
}

.modal-button-success {
  color: $white;
  background-color: $yellowish-green;
  width: 100%;
  height: 90px;
  border: none;
  border-radius: 0px 0px 20px 20px;
  &:hover {
    color: $white-hover;
    background-color: $yellowish-green-hover;
  }
  &:disabled {
    opacity: 0.5;
  }
}

.credit-limit-select {
  position: relative;
  border: none;
  outline: none;
  cursor: pointer;
  font-size: 0.85rem;
  font-weight: 500;
  max-width: 100%;
  height: 50px;
  border-radius: 0.4rem;
  padding: 1rem 3rem 1rem 1.4rem !important;
  background-color: #eff2fc;
  color: #aab6d6;
  margin-bottom: 35px;
  option {
    color: red;
  }
  .custom-select select {
    font-family: Arial;
  }
  .credit-limit-select-item {
  }
}

.dashboard-see-detail-button {
  border-radius: 13px;
  border: none;
  width: fit-content;
  height: 37px;
  color: $dashboard-see-detail-color;
  background-color: $white;
  &:hover {
    background-color: $white-hover;
  }
}

.dashboard-dropdown-item-error {
  color: $carnation !important;
  &:hover {
    background-color: transparent !important;
  }
}

.dashboard-dropdown-item-outofstock {
  color: $marine !important;
  &:hover {
    background-color: transparent !important;
  }
}

.add-user-button {
  background-color: $dark-slate-blue;
  color: $white;
  border-radius: 13px;
  border: none;
  width: fit-content;
  height: 43px;
  font-family: "Poppins", sans-serif;
  &:hover {
    color: $white-hover;
    background-color: $dark-slate-blue-hover;
  }
}

.create_promotion_button {
  background-color: $color-pale-grey;
  color: $sapphire;
  border-radius: 8px;
  width: fit-content;
  height: 35px;
  font-size: 15px;
  font-family: "Poppins", sans-serif;
  border: none;
  img {
    background-repeat: no-repeat;
    background-position: center;
    background-image: url("../images/icons/plus-icon.svg");
  }
  &:hover {
    color: $white-hover;
    background-color: $sapphire;
    img {
      background-repeat: no-repeat;
      background-position: center;
      background-image: url("../images/icons/white-plus-icon.svg");
    }
  }
}

.bulk_buttons {
  background-color: $color-pale-grey;
  color: $sapphire;
  border-radius: 8px;
  width: fit-content;
  height: 35px;
  font-size: 15px;
  font-family: "Poppins", sans-serif;
  border: none;
  img {
    background-repeat: no-repeat;
    background-position: center;
    background-image: url("../images/icons/right_arrow_sapphire_icon.svg");
  }
  &:hover {
    color: $white-hover;
    background-color: $sapphire;
    img {
      background-repeat: no-repeat;
      background-position: center;
      background-image: url("../images/icons/right_arrow_icon.svg");
    }
  }
}

.add-update-from-google-sheet {
  border: none;
  border-radius: 8px;
  padding: 0.45rem;
  background-color: #eafae7;
  img {
    margin-right: 0 !important;
  }
  &:hover {
    background-color: #c9f7c1;
  }
}

.bulk-order-save-button {
  background-color: $yellowish-green;
  color: $white;
  border-radius: 29px;
  border: none;
  font-family: "Poppins", sans-serif;
  width: 228px;
  height: 57px;
  margin-top: 15px;
  margin-bottom: 30px;
  &:hover {
    color: $white-hover;
    background-color: $yellowish-green-hover;
  }
}

.copy-button {
  background-color: $dark-slate-blue;
  color: $white;
  border-radius: 13px;
  border: none;
  width: fit-content;
  height: 45px;
  width: 45px;
  font-family: "Poppins", sans-serif;
  margin-left: 10px;
  img {
    margin: 0 !important;
  }
  &:hover {
    color: $white-hover;
    background-color: $dark-slate-blue-hover;
  }
}

.remove-bulk-order{
  color: #c33b50;
}