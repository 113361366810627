$dark-slate-blue: rgb(22, 37, 78);
$dashboard-time-color: rgb(217, 255, 241);

.promotion-card {
  box-shadow: none;
  border-color: #c6cee3 !important;
  margin: 0;
  border: solid;
  border-width: 1.5px;
  width: 326px;
  border-radius: 25px !important;
  padding: 36px 40px 38px 50px;
  opacity: 0.5;

  label {
    font-weight: 500;
  }

  &.selected {
    opacity: 1;

    .card-title {
      label {
        color: $color-accent !important;
      }
    }
  }

  &:hover {
    cursor: pointer;
  }

  .card-title {
    input {
      margin-right: 12px;
      font-size: 16px;
    }

    label {
      font-size: 16px;
      font-family: "Poppins", sans-serif;
      color: $dark-slate-blue;
      font-weight: 400;
    }

    .countries-header {
      font-size: 14px;
      margin-bottom: 10px;
      font-family: "Poppins", sans-serif;
      color: $dark-slate-blue;
      font-weight: 500;
    }

    .countries {
      margin-left: 15px;
    }

    .currency-header {
      font-size: 14px;
      margin-bottom: 10px;
      font-family: "Poppins", sans-serif;
      color: $dark-slate-blue;
      font-weight: 500;
    }

    .currency {
      margin-left: 10px;
      font-size: 16px;
      margin-bottom: 10px;
      font-family: "Poppins", sans-serif;
      color: $dark-slate-blue;
      font-weight: 500;
    }
  }

  .card-body {
    margin: 0;
    display: flex;
    justify-content: start;
    flex-direction: column;
    font-size: 14px;
    color: $dark-slate-blue;

    .body-row-item {
      margin-top: 8px;
      display: flex;
      justify-content: space-between;
      flex-direction: row;
    }

    label {
      margin: 0;
    }

    p {
      margin: 0;
    }
  }
}

.promotion-detail-percentage-card {
  height: auto;
  overflow: scroll;
  @include desktop-xl {
    height: 360px;
  }
}

.promotion-detail-price-card {
  min-height: 355px;
  .edit-button {
    text-align: start;
    @include desktop-xl {
      text-align: end;
    }
    @include mobile-only {
      text-align: end;
    }
  }
}

.promotion-detail-orders-card-item {
  max-height: 400px;
  overflow: scroll;
}

.dashboard-credit-limit-card {
  background: linear-gradient(to right, rgb(189, 214, 0), rgb(136, 208, 114));
  border-radius: 26px;
  .title {
    color: $white;
    font-size: 19px;
    font-family: "Mont", sans-serif;
    font-weight: 400;
    margin: 0px;
  }
  .price {
    color: $white;
    font-size: 48px;
    font-family: "Mont", sans-serif;
    font-weight: 400;
    margin-bottom: 0px;
    line-height: 48px;
    margin-top: 15px;
  }
  .time {
    color: $dashboard-time-color;
    font-size: 16px;
    font-family: "Mont", sans-serif;
    font-weight: 400;
    margin-top: 0px;
  }
}

.dashboard-inbox-card {
  border-radius: 26px;
  .title {
    color: $marine;
    font-size: 19px;
    font-family: "Mont", sans-serif;
    font-weight: 400;
  }
  .text1 {
    color: $marine;
    font-size: 26px;
    font-family: "Poppins", sans-serif;
    font-weight: 500;
  }
  .text2 {
    color: $marine;
    font-size: 16px;
    font-family: "Poppins", sans-serif;
    font-weight: 500;
  }
  .text3 {
    color: $marine;
    font-size: 16px;
    font-family: "Poppins", sans-serif;
    font-weight: 300;
    .percentage {
      color: $yellowish-green;
    }
  }
}

.dashboard-profits-card {
  border-radius: 26px;
  .title {
    color: $marine;
    font-size: 19px;
    font-family: "Mont", sans-serif;
    font-weight: 400;
  }
}
